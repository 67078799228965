<template>
  <v-container class="text-center">
    <h1>{{ $t('page404Title') }}</h1>
    <p>{{ $t('page404Subtitle') }}</p>
    <p>{{ $t('page404Explanation') }}</p>
    <v-btn color="primary" to="/">{{ $t('home') }}</v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound404',
};
</script>

<style></style>
